import { FC } from "react"
import {
    BannerProp,
    BlocksProp,
    FullTextProp,
    HeroProp,
    OneImageProp,
    ProductsProp,
    ReviewsProp,
    SliderProp,
    TwoImageProp,
    UspProp
} from "@lib/data/_types"
import dynamic from "next/dynamic"
import clsx from "clsx"
import { StoreGetProductsParams } from "@medusajs/medusa/dist/api/routes/store/products/list-products"

const DynamicCleanContainer = dynamic(
    () => import("@modules/common/components/clean-container"),
    { loading: () => <div>Loading...</div> }
)
const DynamicReviewsContainer = dynamic(
    () => import("@modules/common/components/reviews-container"),
    { loading: () => <div>Loading...</div> }
)
const DynamicContentContainer = dynamic(
    () => import("@modules/home/components/content-container"),
    { loading: () => <div>Loading...</div> }
)
const DynamicSmallContentContainer = dynamic(
    () => import("@modules/common/components/small-content-container"),
    { loading: () => <div>Loading...</div> }
)
const DynamicHeroElement = dynamic(
    () => import("@modules/common/components/hero-element"),
    { loading: () => <div>Loading...</div> }
)
const DynamicBlockRender = dynamic(
    () => import("@modules/common/components/block-render"),
    { loading: () => <div>Loading...</div> }
)
const DynamicInfiniteProducts = dynamic(
    () => import("@modules/products/components/infinite-products"),
    { loading: () => <div>Loading...</div> }
)
const DynamicProductSlider = dynamic(
    () => import("@modules/common/components/product-slider"),
    { loading: () => <div>Loading...</div> }
)

const RenderItem: FC<{
    item:
        | undefined
        | HeroProp
        | FullTextProp
        | OneImageProp
        | TwoImageProp
        | BannerProp
        | BlocksProp
        | ProductsProp
        | UspProp
        | SliderProp
        | ReviewsProp
    pos?: number
}> = ({ item, pos }) => {
    switch (item?.type) {
        case "full-text":
            return (
                <div
                    key={`${item?.id}-${item?.type}-${Math.random()}`}
                    className={"col-span-6 renderitem"}
                >
                    <DynamicCleanContainer
                        key={`${item?.id}-${
                            item?.type
                        }-cleancontainer-${Math.random()}`}
                        title={item?.title ?? ""}
                        titleTag={item?.titleType ?? "div"}
                        cta={
                            item?.cta
                                ? {
                                      text: item?.cta?.ctaText,
                                      url: item?.cta?.ctaUrl,
                                      position: item?.cta?.ctaPosition
                                  }
                                : undefined
                        }
                        content={item?.content ?? ""}
                        accordion={item?.accordion ?? []}
                        layout={item?.layout}
                        titleVertical={item?.titleVertical ?? "top"}
                        faqitem={item?.faqitem ?? false}
                    />
                </div>
            )
            break
        case "reviews":
            return (
                <div
                    key={`${item?.id}-${item?.type}-${Math.random()}`}
                    className={"col-span-6 renderitem"}
                >
                    <DynamicReviewsContainer
                        title={item?.title ?? ""}
                        show_title={item?.show_title ?? false}
                    />
                </div>
            )
            break
        case "banner":
            return (
                <div
                    key={`${item?.id}-${item?.type}-${Math.random()}`}
                    className={"col-span-6 renderitem"}
                >
                    <DynamicCleanContainer
                        backgroundImage={item?.image?.url ?? ""}
                        backgroundImageAlt={
                            item?.image?.alternativeText ??
                            item?.image?.caption ??
                            ""
                        }
                        preloadBackground={pos === 0}
                        title={item?.title ?? ""}
                        titleTag={"h1"}
                        cta={
                            item?.cta
                                ? {
                                      text: item?.cta?.ctaText,
                                      url: item?.cta?.ctaUrl,
                                      position: item?.cta?.ctaPosition
                                  }
                                : undefined
                        }
                        content={item?.content ?? ""}
                        accordion={[]}
                        layout={"title top"}
                        titleVertical={item?.titleVertical ?? "top"}
                    />
                </div>
            )
            break
        case "blocks":
            const colsClass: { [key: number]: string } = {
                2: `lg:grid-cols-2`,
                3: `lg:grid-cols-3`,
                4: `lg:grid-cols-4`
            }
            return (
                <div
                    key={`${item?.id}-${item?.type}-${Math.random()}`}
                    className={"col-span-6 mx-4 renderitem"}
                >
                    <div
                        className={clsx(
                            `w-full grid grid-cols-1 ${
                                colsClass[item?.amountPerRow ?? 3]
                            } gap-4 py-8`
                        )}
                    >
                        {item?.blocks?.map((block) => (
                            <DynamicBlockRender
                                key={`block-render-${block?.id}`}
                                block={block}
                                amount={item?.amountPerRow ?? 3}
                            />
                        ))}
                    </div>
                </div>
            )
            break
        case "uspblock":
            return (
                <div
                    key={`uspblock-${Math.random()}`}
                    className={"col-span-6 mx-4 renderitem mb-10 mt-5"}
                >
                    <div
                        className={clsx(
                            `w-full grid grid-cols-1 lg:grid-cols-4 gap-4 py-8  px-4 lg:px-12`
                        )}
                    >
                        {item?.items &&
                            item?.items
                                .sort((a, b) => a.Order - b.Order)
                                ?.map((usp) => (
                                    <div
                                        className={
                                            "flex w-full flex-col items-center justify-center relative max-h-[25vh] py-3 md:py-8 lines-background shadow-[0_25px_60px_-15px_rgba(0,0,0,0.1)] text-brand font-bold text-lg font-display"
                                        }
                                    >
                                        <h4>{usp.content}</h4>
                                    </div>
                                ))}
                    </div>
                </div>
            )
            break
        case "one-image":
            return (
                <div
                    key={`${item?.id}-${item?.type}-${Math.random()}`}
                    className={"col-span-6 renderitem"}
                >
                    <DynamicContentContainer
                        background={"nograin"}
                        image={item?.image?.url ?? ""}
                        imageAlt={item?.image?.alternativeText ?? ""}
                        caption={item?.image?.caption ?? undefined}
                        title={item?.title ?? ""}
                        titleTag={item?.titleType ?? "div"}
                        cta={
                            item?.cta
                                ? {
                                      text: item?.cta?.ctaText,
                                      url: item?.cta?.ctaUrl,
                                      position: item?.cta?.ctaPosition
                                  }
                                : undefined
                        }
                        content={item?.content ?? ""}
                        accordion={item?.accordion ?? []}
                        imageLocation={item?.imageLocation ?? "left 1/3"}
                        preloadImage={pos === 0}
                    />
                </div>
            )
            break
        case "two-image":
            return (
                <div
                    key={`${item?.id}-${item?.type}-${Math.random()}`}
                    className={"col-span-6 renderitem"}
                >
                    <DynamicSmallContentContainer
                        background={"nograin"}
                        image={item?.image?.url ?? ""}
                        imageAlt={item?.image?.alternativeText ?? ""}
                        imageText={item?.image?.caption ?? undefined}
                        image2={item?.image2?.url ?? ""}
                        imageAlt2={item?.image2?.alternativeText ?? ""}
                        imageText2={item?.image2?.caption ?? undefined}
                        title={item?.title ?? ""}
                        titleTag={item?.titleType ?? "div"}
                        cta={
                            item?.cta
                                ? {
                                      text: item?.cta?.ctaText,
                                      url: item?.cta?.ctaUrl,
                                      position: item?.cta?.ctaPosition
                                  }
                                : undefined
                        }
                        content={item?.content ?? ""}
                        accordion={item?.accordion ?? []}
                    />
                </div>
            )
            break
        case "hero":
            return (
                <div
                    key={`${item?.id}-${item?.type}-${Math.random()}`}
                    className={"col-span-6 renderitem"}
                >
                    <DynamicHeroElement
                        image={item?.image?.url ?? ""}
                        image2={item?.image2?.url ?? ""}
                        alt={item?.image?.alternativeText ?? ""}
                        alt2={item?.image2?.alternativeText ?? ""}
                        title={item?.title ?? ""}
                        subtitle={item?.subTitle ?? ""}
                        cta={
                            item?.cta
                                ? {
                                      text: item?.cta?.ctaText,
                                      url: item?.cta?.ctaUrl,
                                      position: item?.cta?.ctaPosition
                                  }
                                : undefined
                        }
                        entranceAnimation={true}
                    />
                </div>
            )
            break
        case "productsgrid":
            const params: StoreGetProductsParams = {
                id: item?.products ?? [],
                collection_id: item?.collections ?? []
            }
            if (item?.limit > 0) params["limit"] = item?.limit
            /*if (item?.order){

            }*/
            return (
                <div
                    key={`${item?.id}-${item?.type}-${Math.random()}`}
                    className={"col-span-6 small:px-8 renderitem pgrid py-6"}
                >
                    <DynamicInfiniteProducts
                        params={params}
                        isRenderItem={true}
                    />
                </div>
            )
            break
        case "slider":
            return (
                <>
                    <div
                        className={clsx(
                            "col-span-6 flex flex-col w-full text-center justify-center text-darkTone mb-2 px-4 lg:px-12 mt-4 md:mt-10 lg:mb-10 small:mb-0"
                        )}
                    >
                        {(typeof item?.title !== "string" ||
                            (item?.title ?? "").trim().length > 5) && (
                            <>
                                <h2
                                    className={clsx(
                                        "font-display font-base subpixel-antialiased",
                                        "text-2xl md:text-3xl lg:text-4xl 2xl:text-5xl",
                                        "mb-3 small:mb-12 "
                                    )}
                                >
                                    {item.title}
                                </h2>
                            </>
                        )}
                    </div>
                    <div
                        className={
                            "col-span-6 flex flex-row renderitem px-4 lg:px-12 items-center"
                        }
                        key={`slider-${item?.id}-${
                            item?.type
                        }-${Math.random()}`}
                    >
                        <DynamicProductSlider item={item} />
                    </div>
                </>
            )
            break
        default:
            return <></>
    }
    return <></>
}

export default RenderItem
